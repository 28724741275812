<template>
  <div></div>
</template>

<script>
export default {
  name: 'MainModal',
  props: [
    'title',
    'text',
    'icon',
    'country',
    'redirect_to_btn_text',
    'stay_btn_text',
    'href',
    'redirect_to_btn_color',
    'stay_btn_color',
    'host',
    'cookie_name'
  ],
  mounted() {
    const stay = this.$cookies.get(this.cookie_name);
    if (!stay) {
      this.showAlert();
    }
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal.fire({
        title: this.title,
        text: this.text.replace('#country', this.country),
        icon: this.icon,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: this.redirect_to_btn_text.replace('#country', this.country),
        confirmButtonColor: this.redirect_to_btn_color,
        cancelButtonText: this.stay_btn_text,
        cancelButtonColor: this.stay_btn_color,
        backdrop: `
          rgba(0,0,123,0.4)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = this.href;
        } else if (result.isDismissed) {
          this.$cookies.set(this.cookie_name, true, -1, null, this.host);
        }
      });
    },
  },
}
</script>
