import Vue from 'vue'
import MainModal from './components/MainModal.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import vueCustomElement from 'vue-custom-element'
import VueCookies from 'vue-cookies'
import 'document-register-element/build/document-register-element'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueSweetalert2)
Vue.use(vueCustomElement)
Vue.customElement('main-modal', MainModal)